/** @jsx jsx */
import { jsx } from 'theme-ui';
import Layout from '../components/layout';
import Cta from '../sections/homepage/cta';

const Terms = () => {
  return (
    <Layout title="Terms - ScrapeOwl">
      <div sx={styles.container}>
        <div sx={styles.content}>
          <h1>Acceptance of the Terms of Service</h1>

          <p>
            Welcome to <strong>ScrapeOwl!</strong> (a.k.a. "we" or "us" or the
            "Company"). We are excited to have you as a user and member of the
            community. The following terms and conditions (collectively, these
            <strong>"Terms of Service"</strong> ) apply to your use of
            www.ScrapeOwl.com, including any content, functionality and services
            offered on or via ScrapeOwl.com (the "Website"). The Terms of
            Service also include our{' '}
            <a href="https://scrapeowl.com/privacy-policy">Privacy Policy</a>.
          </p>

          <p>
            We want to keep our relationship with you as lean and informal as
            possible, but please read the Terms of Service carefully before you
            start using ScrapeOwl, because{' '}
            <strong>
              by using the Website you accept and agree to be bound and abide by
              these Terms of Service.
            </strong>{' '}
            Should you disagree with some of the provisions herein, you can
            either leave the Website (although we'll be sad to see you go!), or
            contact us at support@scrapeowl.com and we'll be happy to hear your
            comments and suggestions.
          </p>

          <h2>2. Changes to the Terms of Service and the Website</h2>
          <p>
            ScrapeOwl is a work in progress, meaning that a lot will change in
            the near future. We reserve the right to update the Website and
            these Terms of Service from time to time, at our discretion. We will
            make sure to announce any major change in a prominent way, but since
            "major" is a very subjective concept, you can always monitor and
            subscribe to changes via our TOS Tracker (yes, we track our own
            Terms!). Of course, this document is public on ScrapeOwl, and you
            will be able to see the changes for any new version (just click on
            the "See all versions" button on the right).{' '}
            <strong>
              Your continued use of the Website following the publishing of
              updated Terms of Service means that you accept and agree to the
              changes.
            </strong>
          </p>

          <h2>3. Accessing the Website,Security and Privacy</h2>
          <p>
            We are working hard on improving ScrapeOwl, but we can't guarantee
            that the Website will be up and running 24/7. We also reserve the
            right to suspend or restrict access to some features to users. In
            any case, we will not be liable if for any reason all or any part of
            the Website is unavailable at any time or for any period, nor for
            any data loss (see also section 7 below).
          </p>

          <p>
            To access certain features of the Website you have to register by
            entering your email and choosing a password as part of our security
            procedures. You must treat such information as confidential, not
            disclosing it to any third party and only using ScrapeOwl in person.
            There is a password reset procedure in case you forget your
            password, but please notify us of any breach of security. We highly
            recommend to choose a strong password and that you log out from your
            account at the end of every session.
          </p>

          <p>
            It is a condition of your use of the Website that all the
            information you provide on the Website is correct, current and
            complete. In the future, you may be asked to provide certain
            registration details or other information. As custom for internet
            websites, we reserve the right to disable any user account, at any
            time in our sole discretion for any or no reason, including, if in
            our opinion you have failed to comply with any provision of these
            Terms of Service.
          </p>

          <p>
            We use SSL encrypted browsing for all logged-in users, but we cannot
            guarantee that all use will be secure. We also do not guarantee that
            the Website or any content provided on the Website is error free. We
            manage your personal data according to our{' '}
            <a href="https://scrapeowl.com/privacy-policy">privacy policy</a>.
          </p>

          <h2>4. Intellectual Property Rights and Use Guidelines.</h2>
          <p>
            The Website and its original content, features and functionality
            (including look!), are owned by ScrapeOwl and are protected by
            United States and international copyright, trademark, patent, trade
            secret and other intellectual property or proprietary rights laws.
            ScrapeOwl is about copying, branching and modifying documents, not
            Website, design or code. So you agree to not copy, modify, create
            derivative works of, publicly display, publicly perform, republish,
            any of our copyrighted material, except to the extent permitted by
            the Website itself. If you have doubts about whether and how to use
            of material on the Website, please address your concerns to:
            support@scrapeowl.com
          </p>

          <p>
            For purposes of these Terms of Service, the term “Content” includes,
            without limitation, information, data, text, photographs, videos,
            audio clips, written posts and comments, software, scripts,
            graphics, and interactive features generated, provided, or otherwise
            made accessible on or through the Website. For the purposes of these
            Terms of Service, “Content” also includes all User Content (as
            defined below).
          </p>

          <p>
            All Content added, created, uploaded, submitted, distributed, or
            posted to the Website by users (collectively “User Content”),
            whether publicly posted or privately transmitted, is the sole
            responsibility of the person who originated such User Content. You
            represent that all User Content provided by you is accurate,
            complete, up-to-date, and in compliance with all applicable laws,
            rules and regulations. You acknowledge that all Content, including
            User Content, accessed by you using the Website is at your own risk
            and you will be solely responsible for any damage or loss to you or
            any other party resulting therefrom. We do not guarantee that any
            Content you access on or through the Website is or will continue to
            be accurate.
          </p>

          <p>
            By submitting public User Content through the Website, you hereby do
            and shall grant us a worldwide, non-exclusive, perpetual,
            royalty-free, fully paid, sublicensable and transferable license to
            use, edit, modify, reproduce, distribute, prepare derivative works
            of, display, perform, and otherwise fully exploit the User Content
            in connection with the Website and our (and our successors’ and
            assigns’) businesses, including without limitation for promoting and
            redistributing part or all of the Website (and derivative works
            thereof) in any media formats and through any media channels
            (including, without limitation, third party websites and feeds). You
            also hereby do and shall grant each user of the Website a
            non-exclusive, perpetual license to access your User Content through
            the Website, and to use, edit, modify, reproduce, distribute,
            prepare derivative works of, display and perform such User Content.
            For clarity, the foregoing license grants to us and our users does
            not affect your other ownership or license rights in your User
            Content, including the right to grant additional licenses to your
            User Content, unless otherwise agreed in writing. You represent and
            warrant that you have all rights to grant such licenses to us
            without infringement or violation of any third party rights,
            including without limitation, any privacy rights, publicity rights,
            copyrights, trademarks, contract rights, or any other intellectual
            property or proprietary rights.
          </p>

          <p>
            We do not guarantee that any Content will be made available on the
            Website. We reserve the right to, but do not have any obligation to,
            (i) remove, edit or modify any Content in our sole discretion, at
            any time, without notice to you and for any reason (including, but
            not limited to, upon receipt of claims or allegations from third
            parties or authorities relating to such Content or if we are
            concerned that you may have violated these Terms of Service), or for
            no reason at all and (ii) to remove or block any Content from the
            Website.
          </p>

          <p>
            You are permitted to use the Website for your personal,
            non-commercial use, or legitimate business purposes, provided that
            your activities are lawful and in accordance with these Terms of
            Service. Prohibited uses include violation of laws and regulations,
            hacking the Website in any manner, or violating the Content
            Standards set below. No right, title or interest in or to the
            Website or any content on the site is transferred to you, and all
            rights not expressly granted are reserved. Any use of the Website
            not expressly permitted by these Terms of Service is a breach of
            these Terms of Service and can lead to account termination.
          </p>

          <p>
            We ecourage your feedback, in the form of reviews, comments, and
            suggestions or recommendations for modifications, improvements or
            changes to the Services or the Site that you may choose in your sole
            discretion to provide us from time to time (“Feedback”). When you
            provide Feedback, you grant us, under all right, title and interest
            in and to the Feedback, a non-exclusive, royalty-free, worldwide,
            transferable, sub-licensable, irrevocable, perpetual license to use
            that Feedback or to incorporate it into the Website or other producs
            or services.{' '}
          </p>

          <p>
            The Website may contain Content specifically provided by us, our
            partners or our users and such Content is protected by copyrights,
            trademarks, service marks, patents, trade secrets or other
            proprietary rights and laws. You shall abide by and maintain all
            copyright notices, information, and restrictions contained in any
            Content accessed through the Website.
          </p>

          <h2>
            5. User Contributions and Content Standards. Special Disclaimer for
            legal content.
          </h2>
          <p>
            ScrapeOwl contains user generated content, and also may contain
            message boards, personal web pages, forums or other interactive
            features that allow you to upload, post, submit, publish, display or
            transmit to other Users Content on or through the Website. All User
            Content must be lawful, not spammy, and clear of virus or other
            malware. More specifically, but without limiting the foregoing, it
            must comply with the following Content Standards:
          </p>

          <ul>
            <li>
              Not contain any material which is defamatory, obscene, indecent,
              abusive, offensive, harassing, violent, hateful, inflammatory or
              otherwise objectionable.
            </li>

            <li>
              Not promote sexually explicit or pornographic material, violence,
              or discrimination based on race, sex, religion, nationality,
              disability, sexual orientation or age.
            </li>

            <li>
              Not infringe any patent, trademark, trade secret, copyright or
              other intellectual property rights of any other person.
            </li>

            <li>
              Not violate the legal rights (including the rights of publicity
              and privacy) of others or contain any material that could give
              rise to any civil or criminal liability under applicable laws or
              regulations or that otherwise may be in conflict with these Terms
              of Service.
            </li>

            <li>Not be likely to deceive any person.</li>

            <li>
              Not promote any illegal activity, or advocate, promote or assist
              any unlawful act.
            </li>

            <li>
              Not cause annoyance, inconvenience or needless anxiety or be
              likely to upset, embarrass, alarm or annoy any other person.
            </li>

            <li>
              Not be used to impersonate any person, or to misrepresent your
              identity or affiliation with any person or organization.
            </li>

            <li>
              Not involve commercial activities or sales , such as contests,
              sweepstakes and other sales promotions, barter oradvertising.
            </li>

            <li>
              Not give the impression that they emanate from us or any other
              person or entity, if this is not the case.
            </li>

            <li>
              Not used, if public, as test empty documents with no actual
              content, or typing exercises: please respect our community and
              help us minimize the clutter!
            </li>
          </ul>

          <p>
            <strong>
              User Content is not intended to be legal advice nor form an
              attorney-client relationship, nor among the users, nor between the
              users and ScrapeOwl.Use of the Website should never be understood
              to be replacing use of a qualified attorney, and ScrapeOwl's
              relationship to all documents and transactions completed using the
              Website is that of a trusted, disinterested third party.
            </strong>
          </p>

          <h2>6. Law and Copyright Infringment</h2>
          <p>
            We do not undertake to review all material before it is posted on
            the Website, and cannot ensure prompt removal of objectionable
            material after it has been posted. Accordingly, we assume no
            liability for any action or inaction regarding transmissions,
            communications or content provided by any user or third party. In
            the unlikely event we receive a disclosure request from an
            authorized party, we reserve the right to disclose user identities
            when required to do so by the law, including in response to a law
            enforcement request supported by a valid court order. You waive and
            hold harmless the Company from any claims resulting from any action
            taken by the Company during or as a result of its investigations and
            from any actions taken as a consequence of investigations by either
            the Company or law enforcement authorities.
          </p>

          <p>
            If you believe that any User Content violate your copyright, please
            follow the procedure set forth by article 512(c) of the DMCA and
            provide us a written takedown notice including the following
            information:{' '}
          </p>

          <ol>
            <li>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
            <li>
              Identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works at that site.
            </li>
            <li>
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit us to locate the material.
            </li>
            <li>
              Information reasonably sufficient for us to contact you, such as
              email, address, telephone number.
            </li>
            <li>
              A statement that you a good faith belief that use of the material
              in the manner complained of is not authorized by the copyright
              owner, its agent, or the law.
            </li>
            <li>
              A statement that the information in the notification is accurate,
              and under penalty of perjury, that you is authorized to act on
              behalf of the owner of an exclusive right that is allegedly
              infringed.
            </li>
          </ol>

          <p>
            The notice should be addressed to{' '}
            <a href="mailto:dmca@ScrapeOwl.com">dmca@scrapeowl.com.</a>
          </p>

          <h2>
            Disclaimer of Warranties, Limitations of Liability and
            Indemnification.
          </h2>
          <p>
            <strong>
              Your use of ScrapeOwl is at your sole risk. The service is
              provided "as is" and "as available".
            </strong>{' '}
            We disclaim all warranties of any kind, express or implied,
            including, without limitation, the warranties of merchantability,
            fitness for a particular purpose and non-infringement. We are not
            liable for damages, direct or consequential, resulting from your use
            of the Website, and you agree to defend, indemnify and hold us
            harmless from any claims, losses, liability costs and expenses
            (including but not limites to attorney's fees) arising from your
            violation of any third-party's rights. You acknowledge that you have
            only a limited, non-exclusive, nontransferable license to use the
            Website. Because the Website is not error or bug free, you agree
            that you will use it carefully and avoid using it ways which might
            result in any loss of your or any third party's property or
            information.
          </p>

          <h2>8. Geographic Restrictions</h2>
          <p>
            For now, we provide this Website for use only by persons located in
            the United States. We make no claims that the Website or any of its
            content is accessible, appropriate or legal outside of the United
            States. If you access the Website from outside the United States,
            you do so on your own initiative and are responsible for compliance
            with local laws.
          </p>

          <h2>9. Governing Law and Jurisdiction</h2>
          <p>
            These Terms of Service and any dispute or claim arising out of, or
            related to them, shall be governed by and construed in accordance
            with the internal laws of the State of New York without giving
            effect to any choice or conflict of law provision or rule.{' '}
          </p>

          <p>
            Any legal suit, action or proceeding arising out of, or related to,
            these Terms of Service or the Website shall be instituted
            exclusively in the federal courts of the United States or the courts
            of the State of New York.{' '}
          </p>

          <h2>10. Waiver and Severability</h2>
          <p>
            Our failure to exercise or enforce any right or provision of the
            Terms of Service shall not constitute a waiver of such right or
            provision. The Terms of Service constitutes the entire agreement
            between you and ScrapeOwl and govern your use of the service,
            superseding any prior agreements (including, but not limited to, any
            prior versions of the Terms of Service). If any provision of these
            Terms of Service is held by a court of competent jurisdiction to be
            invalid, illegal or unenforceable for any reason, such provision
            shall be eliminated or limited to the minimum extent such that the
            remaining provisions of the Terms of Service will continue in full
            force and effect.
          </p>

          <h2>11. Feedback</h2>
          <p>
            We welcome any comment, question and communication at{' '}
            <a href="mailto:team@scrapeowl.com">team@scrapeowl.com</a>
          </p>
        </div>

        <Cta />
      </div>
    </Layout>
  );
};

export default Terms;

const styles = {
  container: {
    variant: 'layout.container',
  },

  content: {
    my: 6,
    maxWidth: '80ch',
    wordWrap: 'break-word',
    color: 'rgb(55, 41, 44)',
    mx: 'auto',

    '& > * + *': {
      mt: 2,
    },

    h1: {
      fontSize: [3, 4, 5],
      textAlign: 'center',
      mb: 3,
    },

    h2: {
      fontSize: 4,
    },

    h3: {
      fontSize: [3],
    },

    'h1, h2, h3': {
      color: 'purplegray',
      fontWeight: 'bold',
    },

    'h1 + p, h2 + p, h3 + p': {
      mt: 1,
    },

    '* + h1, * + h2, * + h3': {
      mt: [4, 5],
    },

    p: {
      fontSize: '1rem',
      mt: 4,
    },

    ol: {
      pl: '1rem',
      mt: 4,
    },

    ul: {
      pl: '1rem',
      mt: 4,
    },

    li: {
      listStyleType: 'disc',
    },

    '& li + li': {
      mt: 2,
    },

    a: {
      color: 'purple',
      fontWeight: 'bold',
    },

    'a:hover': {
      textDecoration: 'underline',
    },
  },
};
