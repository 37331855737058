/** @jsx jsx */
import { jsx } from 'theme-ui';

const Cta = () => {
  return (
    <div sx={styles.outerContainer}>
      <section sx={styles.container}>
        <div sx={{ display: 'flex', flexDirection: 'column', mr: [0, 4, 0] }}>
          <h2 sx={styles.heading}>Ready to Start Scraping?</h2>
          <p sx={styles.subHeading}>Start your free trial today.</p>
          <p sx={styles.footer}>
            Collect and access data with code or no-code.
          </p>
        </div>

        <a sx={styles.ctaLink} href="https://app.scrapeowl.com/register">
          Get Started
        </a>
      </section>
    </div>
  );
};

export default Cta;

const styles = {
  outerContainer: {
    variant: 'layout.container',
  },
  container: {
    display: 'flex',
    textAlign: ['center', 'left'],
    flexDirection: ['column', 'row'],
    alignItems: 'center',
    justifyContent: 'space-between',
    background: 'radial-gradient(50% 50% at 50% 50%, #525376 0%, #404163 100%)',
    borderRadius: '24px',
    border: '1px solid #272746',
    boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.25)',
    pt: '36px',
    pb: [4, '20px'],
    px: [3, '80px'],
    color: 'white',
    mb: '236px',
  },
  heading: {
    fontSize: [4, 4, '32px'],
    fontWeight: 600,
  },
  subHeading: {
    fontWeight: 400,
    opacity: 0.4,
    fontSize: [3, 3, '28px'],
  },
  footer: {
    opacity: 0.4,
    fontSize: [1, 1, '16px'],
    fontWeight: 600,
    mt: '24px',
  },
  ctaLink: {
    minWidth: '200px',
    width: ['100%', 'unset'],
    textAlign: 'center',
    py: '13px',
    px: '25px',
    color: 'white',
    bg: 'orange',
    borderRadius: '8px',
    fontWeight: 600,
    transition: 'background 0.25s ease-out',
    mt: [4, 0],

    '&:hover': {
      bg: '#e67d42',
    },
  },
};
